import React, { FunctionComponent, useMemo } from 'react';
import { Col, Container, Row } from '@visalex/core';

import { useTranslation } from 'next-i18next';

import Benefits, { BenefitProps } from '../../Benefits/Benefits';

import VisaCards from './VisaCards/VisaCards';

import styles from './HowWeHelpSection.module.scss';

const prefix = 'how-we-help';

const HowWeHelpSection: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const benefits: BenefitProps[] = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5].map((index) => ({
        key: index,
        title: `${t('home.benefits.step')} ${index + 1}`,
        description: t(`home.benefits.items.${index}`),
        imageName: `benefit_${index + 1}.svg`,
      })),
    [i18n.language]
  );

  return (
    <section className={styles[`${prefix}`]} data-testid="how-we-help-section">
      <Container>
        <Row className={styles[`${prefix}__cards`]}>
          <VisaCards data-testid="visa-cards" />
        </Row>
        <Row className={styles[`${prefix}__content`]}>
          <Col xl={8} className="mx-auto">
            <h2>{t(`home.${prefix}.title`)}</h2>
            <p>{t(`home.${prefix}.description`)}</p>
          </Col>
        </Row>
        <Row className={styles[`${prefix}_benefits`]}>
          <Benefits benefits={benefits} colProps={{ xs: 6, lg: 4, xl: { span: 3, offset: 1 } }} />
        </Row>
      </Container>
    </section>
  );
};

export default HowWeHelpSection;
