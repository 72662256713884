import React, { FunctionComponent } from 'react';
import { Col, Hero, Row } from '@visalex/core';

import cx from 'clsx';
import { useTranslation } from 'next-i18next';

import HeroSectionCTA from './HomeHeroSectionCTA/HomeHeroSectionCTA';

import styles from './HomeHeroSection.module.scss';

const HomeHeroSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Hero className={styles['hero-section']}>
      <Row className={cx(styles['hero-section__row'], 'flex-column', 'flex-lg-row')}>
        <Col md={7} lg={5} className={styles['hero-section__leftside']}>
          <h1>
            <span>{t('home.hero-section.your-us')}&#160;</span>
            <br />
            <span dangerouslySetInnerHTML={{ __html: t('home.hero-section.immigration-goal') }} />
            <br />
            <span>{t('home.hero-section.us-immigration')}</span>
          </h1>
          <p>{t('home.hero-section.online-platform')}</p>
        </Col>
        <Col md={12} lg={7} className={styles['hero-section__rightside']}>
          <img src="/images/eagle.png" alt={t('home.hero-section.imageAlt.eagle')} width={756.64} height={860} />
        </Col>
      </Row>
      <Row>
        <HeroSectionCTA />
      </Row>
    </Hero>
  );
};

export default HomeHeroSection;
