import React from 'react';
import { appSettings } from '@visalex/configs';
import { ComponentWithProps, Page } from '@visalex/core';

import Head from 'next/head';

export type LayoutProps = {
  title?: string;
  description?: string;
};

export const Layout: ComponentWithProps<LayoutProps> = ({
  children,
  title = appSettings.seo.title,
  description = appSettings.seo.description,
  ...props
}) => (
  <div {...props}>
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    <Page>{children}</Page>
  </div>
);
