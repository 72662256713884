import React from 'react';
import { ComponentWithProps, Container } from '@visalex/core';

import cx from 'clsx';

import styles from './Testimonials.module.scss';

const TestimonialsSectionContainer: ComponentWithProps = ({ className, children }) => {
  return (
    <section className={cx(styles.testimonials, className && className)}>
      <Container>{children}</Container>
    </section>
  );
};

export default TestimonialsSectionContainer;
