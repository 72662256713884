import React from 'react';
import HomeHeroSection from '@/components/Home/HomeHeroSection/HomeHeroSection';
import HowWeHelpSection from '@/components/Home/HowWeHelpSection/HowWeHelpSection';
import TestimonialsSection from '@/components/Home/TestimonialsSection/TestimonialsSection';
import WhyVisaLexSection from '@/components/Home/WhyVisaLexSection/WhyVisaLexSection';
import { Layout } from '@/components/Layout';

import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const IndexPage: NextPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('home.seo-title')} description={t('home.seo-description')}>
      <HomeHeroSection />
      <HowWeHelpSection />
      <WhyVisaLexSection />
      <TestimonialsSection />
    </Layout>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default IndexPage;
