import React, { FunctionComponent } from 'react';
import Statistics from '@/components/Home/WhyVisaLexSection/Statistics/Statistics';
import { Col, Container, Row } from '@visalex/core';

import { useTranslation } from 'next-i18next';

import NativeLanguage from './NativeLanguage/NativeLanguage';
import Progress from './Progress/Progress';

import styles from './WhyVisaLexSection.module.scss';

const WhyVisaLexSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['why-visalex-section']} data-testid="why-visalex-section">
      <Container>
        <Row className={styles['why-visalex-section__content']}>
          <Col xl={8} className="mx-auto">
            <h2>{t('home.why-visalex.title')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('home.why-visalex.description') }} />
          </Col>
        </Row>
        <Statistics />
        <Progress />
        <NativeLanguage />
      </Container>
    </section>
  );
};

export default WhyVisaLexSection;
