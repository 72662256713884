import React, { FunctionComponent, useState } from 'react';
import { Col, Container, Row } from '@visalex/core';
import VisibilitySensor from 'react-visibility-sensor';

import cx from 'clsx';
import { TFunction } from 'i18next';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import styles from './NativeLanguage.module.scss';

const NativeLanguageIllustration: FunctionComponent<{ t: TFunction }> = ({ t }) => {
  const [isVisible, setState] = useState<boolean>(false);

  return (
    <VisibilitySensor
      partialVisibility
      minTopValue={300}
      offset={{ top: -10000 }}
      onChange={(isVisibleNew: boolean) => !isVisible && setState(isVisibleNew)}
    >
      {() => (
        <div
          className={cx(styles['native-language__images'], { [styles['native-language__images--visible']]: isVisible })}
        >
          <div className={styles['native-language__images-holder']}>
            <div className={styles['native-language__images-imac']}>
              <Image
                src="/images/multilang_support/imac.svg"
                alt={t('home.why-visalex.native-language.imageAlt.greeting')}
                width={230}
                height={330}
              />
            </div>
            <div className={styles['native-language__images-hi']}>
              <Image
                src="/images/multilang_support/hi_bubbe.svg"
                alt={t('home.why-visalex.native-language.imageAlt.en')}
                width={80}
                height={110}
              />
            </div>
            <div className={styles['native-language__images-hola']}>
              <Image
                src="/images/multilang_support/hola_bubble.svg"
                alt={t('home.why-visalex.native-language.imageAlt.es')}
                width={160}
                height={160}
              />
            </div>
            <div className={styles['native-language__images-ola']}>
              <Image
                src="/images/multilang_support/ola_bubble.svg"
                alt={t('home.why-visalex.native-language.imageAlt.pt')}
                width={125}
                height={150}
              />
            </div>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );
};

const NativeLanguage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['native-language']}>
      <Container>
        <Row className={styles['native-language__content']} noGutters>
          <Col xs={12} lg={6}>
            <NativeLanguageIllustration t={t} />
          </Col>
          <Col xs={12} lg={{ span: 5, offset: 1 }} className="d-flex flex-column justify-content-center">
            <h3>{t('home.why-visalex.native-language.title')}</h3>
            <p dangerouslySetInnerHTML={{ __html: t('home.why-visalex.native-language.description') }}></p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NativeLanguage;
