import React from 'react';

import { TFunction } from 'i18next';
import Image from 'next/image';

export const progressSteps = (t: TFunction) => {
  return [
    {
      key: 1,
      icon() {
        return (
          <Image
            src="/icons/progress/AP_1_fill_in_information_ico.svg"
            alt={t('home.why-visalex.progress.steps.0')}
            width={40}
            height={40}
          />
        );
      },
    },
    {
      key: 2,
      icon() {
        return (
          <Image
            src="/icons/progress/AP_2_review_and_sign_ico.svg"
            alt={t('home.why-visalex.progress.steps.1')}
            width={40}
            height={40}
          />
        );
      },
    },
    {
      key: 3,
      icon() {
        return (
          <Image
            src="/icons/progress/AP_3_file_your_ico.svg"
            alt={t('home.why-visalex.progress.steps.2')}
            width={40}
            height={40}
          />
        );
      },
    },
    {
      key: 4,
      icon() {
        return (
          <Image
            src="/icons/progress/AP_4_Interview_process_ico.svg"
            alt={t('home.why-visalex.progress.steps.3')}
            width={40}
            height={40}
          />
        );
      },
    },
    {
      key: 5,
      icon() {
        return (
          <Image
            src="/icons/progress/AP_5_get_your_visa_ico.svg"
            alt={t('home.why-visalex.progress.steps.4')}
            width={40}
            height={40}
          />
        );
      },
    },
  ];
};
