import React, { FunctionComponent, useState } from 'react';
import { Button } from '@visalex/core';

import { useTranslation } from 'next-i18next';

import VideoModal from '../../../VideoModal/VideoModal';

import styles from '../HomeHeroSection.module.scss';

const HomeHeroSectionCTA: FunctionComponent = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className="mx-auto mx-lg-0" data-testid="hero-section-cta">
      <Button theme="default" className={styles['hero-section__CTA-btn']} onClick={() => setOpen(true)}>
        <img
          src="/icons/play_button_normal.svg"
          className={styles['hero-section__CTA-btn--normal']}
          alt={t('home.hero-section.watch-video')}
          width={56}
          height={56}
        />
        <span>{t('home.hero-section.watch-video')}</span>
      </Button>
      <VideoModal isOpen={open} onRequestClose={() => setOpen(false)} />
    </div>
  );
};

export default HomeHeroSectionCTA;
