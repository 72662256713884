import React, { FunctionComponent } from 'react';
import { Col, ComponentWithProps, Link, LinkButton } from '@visalex/core';

import cx from 'clsx';
import { TFunction } from 'i18next';
import { useTranslation } from 'next-i18next';

import styles from './VisaCards.module.scss';

const prefix = 'visa-card';

const VisaCard: ComponentWithProps<{
  title: string;
  t: TFunction;
  link: string;
}> = ({ className, children, title, link, t }) => (
  <article className={className} data-testid="visa-card">
    <div className={styles[`${prefix}__header`]}>
      <h4>
        <Link href={link}>{title}</Link>
      </h4>
    </div>
    <div className={cx(styles[`${prefix}__content`], 'd-flex flex-column')}>
      {children}
      <LinkButton href="/free-consultation" size="lg" outline>
        {t('nav-bar.schedule-consultation')}
      </LinkButton>
      <small>{t('common.supported-locales')}</small>
    </div>
  </article>
);

const VisaCards: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Col xs={12} lg={6} className={cx('order-1 order-lg-0', styles[`${prefix}__holder`])}>
        <VisaCard
          className={cx(styles[`${prefix}`], styles['work-visa'])}
          title={t('home.cards.work-visa.title')}
          t={t}
          link="visas"
        >
          <p dangerouslySetInnerHTML={{ __html: t('home.cards.work-visa.description') }}></p>
        </VisaCard>
      </Col>
      <Col xs={12} lg={6} className={cx('order-1 order-lg-0', styles['visa-card__holder'])}>
        <VisaCard
          className={cx(styles[`${prefix}`], styles['green-card'])}
          title={t('home.cards.green-card.title')}
          t={t}
          link="green-cards"
        >
          <p dangerouslySetInnerHTML={{ __html: t('home.cards.green-card.description') }}></p>
        </VisaCard>
      </Col>
    </>
  );
};

export default VisaCards;
