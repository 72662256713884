import React, { FunctionComponent, useState } from 'react';
import StepsSlider from '@/components/Home/WhyVisaLexSection/Progress/StepsSlider';
import { animated, config, useSpring } from '@react-spring/web';
import { Col, ComponentWithProps, PieChart, Row } from '@visalex/core';
import VisibilitySensor from 'react-visibility-sensor';

import cx from 'clsx';
import { TFunction, useTranslation } from 'next-i18next';

import { progressSteps } from './ProgressConstants';

import styles from './Progress.module.scss';

export type ProgressStepsProps = {
  state: number;
  t: TFunction;
};

export const ProgressSteps: ComponentWithProps<ProgressStepsProps> = ({ t, state, className, ...rest }) => {
  return (
    <div {...rest} className={cx(className, styles.progress__steps)}>
      {progressSteps(t).map((step) => {
        const Icon = step.icon;

        return (
          <div
            key={step.key}
            className={cx(styles['progress__steps-step'], {
              [styles['progress__steps-step--active']]: state === step.key,
            })}
            data-key={step.key}
          >
            <Icon />
            <h6>{t(`home.why-visalex.progress.steps.${step.key - 1}`)}</h6>
          </div>
        );
      })}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let interval: any;
const Progress: FunctionComponent = () => {
  const { t } = useTranslation();
  const [activeState, setActive] = useState<number>(0);

  const { state } = useSpring<{ state: number }>({
    state: activeState * 20,
    config: { ...config.default, duration: 500 },
  });

  return (
    <Row className={styles.progress}>
      <Col xs={12} lg={10} xl={6} className={styles.progress__leftside}>
        <h3>{t('home.why-visalex.progress.title')}</h3>
        <p>{t('home.why-visalex.progress.description')}</p>
      </Col>
      <Col xs={12} xl={6} className={styles.progress__rightside}>
        <div
          className={cx(styles['progress__circle-holder'], {
            [styles['progress__circle-holder--active']]: activeState === 5,
          })}
        >
          <ProgressSteps state={activeState} t={t} className="d-none d-md-block" />
          <StepsSlider setActive={setActive} />

          <VisibilitySensor
            delayedCall
            partialVisibility
            minTopValue={200}
            offset={{ top: -10000 }}
            onChange={(isVisible: boolean) => {
              let currentState = activeState;

              if (isVisible) {
                interval = setInterval(() => {
                  currentState += 1;

                  if (currentState < 6 && window.innerWidth >= 768) {
                    setActive(currentState);
                  } else {
                    clearInterval(interval);
                  }
                }, 850);
              } else {
                clearInterval(interval);
              }
            }}
          >
            {() => (
              <>
                <PieChart
                  rounded
                  data={[{ value: 1, key: 1, color: '#00A535' }]}
                  reveal={activeState * 20}
                  radius={50}
                  lineWidth={11}
                  startAngle={270}
                  background="#000b76"
                  lengthAngle={360}
                  className={cx(styles.progress__circle, 'd-none d-md-block')}
                  animate
                  animationDuration={500}
                />
                <div className={cx(styles.line, 'd-block d-md-none')}>
                  <animated.div
                    style={{ transform: state.to((s) => `translateX(-${100 - Number(s)}%)`) }}
                    className={styles['line__active-bar']}
                  />
                </div>
                <div className={styles.progress__content}>
                  <h4>
                    <animated.span>{state.to((s) => Number(s).toFixed(0))}</animated.span>%
                  </h4>
                  <h5>{t('home.why-visalex.progress.application-progress')}</h5>
                </div>
              </>
            )}
          </VisibilitySensor>
          <div className="blurred-ellipse-bg" />
        </div>
      </Col>
    </Row>
  );
};

export default Progress;
