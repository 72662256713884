import React, { FunctionComponent } from 'react';
import TestimonialsCTA from '@/components/Testimonials/TestimonialsCTA';
import TestimonialsSectionContainer from '@/components/Testimonials/TestimonialsSectionContainer';

import styles from './TestimonialsSection.module.scss';

const TestimonialsSection: FunctionComponent = () => {
  return (
    <TestimonialsSectionContainer>
      <div className={styles['testimonials__CTA--padding']} data-testid="testimonials-section">
        <TestimonialsCTA />
      </div>
    </TestimonialsSectionContainer>
  );
};

export default TestimonialsSection;
