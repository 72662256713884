import React, { FunctionComponent, memo, useState } from 'react';
import { animated, useTrail } from '@react-spring/web';
import { Col, ColProps } from '@visalex/core';
import VisibilitySensor from 'react-visibility-sensor';

import Image from 'next/image';

import styles from './Benefits.module.scss';

export type BenefitProps = {
  key: number;
  imageName: string;
  imageAlt?: string;
  title?: string;
  description?: string;
};

const Benefit: FunctionComponent<BenefitProps> = memo(({ imageName, imageAlt, title, description }) => (
  <>
    <Image src={`/icons/${imageName}`} alt={imageAlt || title || description || ''} width={90} height={90} />
    {title && <h5>{title}</h5>}
    {description && <p>{description}</p>}
  </>
));

Benefit.displayName = 'Benefit';

export interface BenefitsProps {
  benefits: BenefitProps[];
  colProps?: ColProps;
}

const Benefits: FunctionComponent<BenefitsProps> = ({ benefits, colProps = { md: 5, xl: 3 } }) => {
  const [isVisible, setVisibility] = useState(false);
  const trail = useTrail<{ opacity: number }>(benefits.length, {
    from: { opacity: 0 },
    opacity: isVisible ? 1 : 0,
  });

  return (
    <VisibilitySensor
      partialVisibility
      delayedCall
      minTopValue={100}
      offset={{ top: -10000 }}
      onChange={(newIsVisible: boolean) => !isVisible && setVisibility(newIsVisible)}
    >
      {() =>
        trail.map(({ opacity }, index) => (
          <Col key={index} {...colProps} className={styles.benefit}>
            <animated.div style={{ opacity }}>
              <Benefit {...benefits[index]} />
            </animated.div>
          </Col>
        ))
      }
    </VisibilitySensor>
  );
};

export default Benefits;
