import React, { FunctionComponent } from 'react';
import { config } from '@react-spring/web';
import { ModalDialog } from '@visalex/core';

import { useTranslation } from 'next-i18next';

import styles from './VideoModal.module.scss';

type VideoModalProps = {
  isOpen: boolean;
  onRequestClose(): void;
};
const VideoModal: FunctionComponent<VideoModalProps> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t('home.hero-section.video-title')}
      modalTransition={{ config: { ...config.stiff, duration: 200 } }}
      size="lg"
      onRequestClose={onRequestClose}
      className={styles['video-modal']}
    >
      <iframe
        src={t('home.hero-section.video-url')}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </ModalDialog>
  );
};

export default VideoModal;
