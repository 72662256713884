import React, { FunctionComponent, useState } from 'react';
import { animated, config, useSpring } from '@react-spring/web';
import { Col, Row } from '@visalex/core';
import VisibilitySensor from 'react-visibility-sensor';

import cx from 'clsx';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import styles from './Statistics.module.scss';

const CASES_COUNTER = 5000;
const INITIAL_COUNTER = 4000;

export function formatNumberWithCommas(x: number) {
  return x.toFixed(0).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',') + (x === 3500 ? '+' : '');
}

const Statistics: FunctionComponent = () => {
  const { t } = useTranslation();
  const [{ counter }, setState] = useState<{ counter: number }>({
    counter: INITIAL_COUNTER,
  });
  const spring = useSpring<{ counter: number }>({
    counter,
    config: { ...config.molasses, duration: 3500 },
  });

  return (
    <Row className={styles.statistics}>
      <Col xs={12} xl={5} className={cx(styles.statistics__holder, 'order-1 order-xl-0')}>
        <VisibilitySensor
          delayedCall
          partialVisibility
          minTopValue={100}
          offset={{ top: -10000 }}
          onChange={(isVisible: boolean) =>
            setState({ counter: counter === CASES_COUNTER || isVisible ? CASES_COUNTER : INITIAL_COUNTER })
          }
        >
          {() => (
            <div className="d-flex flex-wrap">
              <Col xs={12} md={5} className={styles.statistics__topic}>
                <h4>
                  <animated.span>{spring.counter.to((c) => formatNumberWithCommas(c as number))}</animated.span>
                </h4>
                <h5>{t('home.why-visalex.statistics.applications')}</h5>
              </Col>
              <Col xs={12} md={{ span: 5, offset: 2 }} className={styles.statistics__topic}>
                <h4>97%</h4>
                <h5>{t('home.why-visalex.statistics.success-rate')}</h5>
              </Col>
              <Col xs={12} md={5} className={styles.statistics__topic}>
                <Image
                  src="/icons/individuals_and_businesses_ico.svg"
                  alt={t('home.why-visalex.statistics.individuals-and-businesses')}
                  width={90}
                  height={90}
                />
                <h5>{t('home.why-visalex.statistics.individuals-and-businesses')}</h5>
              </Col>
              <Col xs={12} md={{ span: 5, offset: 2 }} className={styles.statistics__topic}>
                <Image
                  src="/icons/full_cycle_case_management_ico.svg"
                  alt={t('home.why-visalex.statistics.case-management')}
                  width={90}
                  height={90}
                />
                <h5>{t('home.why-visalex.statistics.case-management')}</h5>
              </Col>
            </div>
          )}
        </VisibilitySensor>
        <div className="blurred-ellipse-bg" />
      </Col>
      <Col
        xs={12}
        xl={{ span: 5, offset: 1 }}
        className={cx('px-0 d-flex flex-column justify-content-center order-0 order-xl-1', styles.statistics__content)}
      >
        <h3>{t('home.why-visalex.statistics.title')}</h3>
        <p>{t('home.why-visalex.statistics.description')}</p>
      </Col>
    </Row>
  );
};

export default Statistics;
