import React from 'react';
import { animated } from '@react-spring/web';
import { ComponentWithProps, Slider } from '@visalex/core';

import cx from 'clsx';
import { useTranslation } from 'next-i18next';

import { progressSteps } from './ProgressConstants';

import progressStyles from './Progress.module.scss';

export interface IStepsSlider {
  setActive: (state: number) => void;
}

const StepsSlider: ComponentWithProps<IStepsSlider> = ({ setActive, className, ...rest }) => {
  const { t } = useTranslation();

  return (
    <div className={progressStyles['progress__steps-holder']}>
      <Slider
        {...rest}
        showArrows
        showDots={false}
        arrowColor="#fff"
        className={cx(className, progressStyles.progress__steps)}
        pageRotationThreshold={1 / 4}
        onPageCompleted={(pageId) => setActive(pageId + 1)}
      >
        {progressSteps(t).map((item) => {
          const Icon = item.icon;

          return (
            <animated.div
              key={item.key}
              className={cx(progressStyles['progress__steps-step'], progressStyles['progress__steps-step--active'])}
              data-key={item.key}
            >
              <div className={progressStyles['progress__steps-step-content']}>
                <Icon />
                <h6>{t(`home.why-visalex.progress.steps.${item.key - 1}`)}</h6>
              </div>
            </animated.div>
          );
        })}
      </Slider>
    </div>
  );
};

export default StepsSlider;
