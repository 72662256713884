import React, { FunctionComponent } from 'react';
import { Col, LinkButton, Row } from '@visalex/core';

import { useTranslation } from 'next-i18next';

import styles from './Testimonials.module.scss';

export type TestimonialsCTAProps = {
  header?: string;
  subtitle?: string;
  sidebar?: boolean;
  withImg?: boolean;
};

const TestimonialsCTA: FunctionComponent<TestimonialsCTAProps> = ({ header, subtitle, sidebar, withImg = false }) => {
  const { t } = useTranslation();

  return (
    <Row className={!sidebar ? styles.testimonials__CTA : styles.testimonials__CTA_sidebar}>
      <Col className="text-center">
        {withImg && (
          <img
            className={!sidebar ? styles.testimonials__img : styles.testimonials__img_sidebar}
            src="/images/testimonials/not-sure.png"
            alt={t('common.testimonials-cta.imageAlt.cta-image')}
          />
        )}
        <h2 className="testimonials__title">{header || t('common.testimonials-cta.title')}</h2>
        <h6 dangerouslySetInnerHTML={{ __html: subtitle || t('common.testimonials-cta.subtitle') }} />
        <LinkButton outline={withImg} size="lg" href="/visa-finder">
          {t('common.testimonials-cta.cta-button-text')}
        </LinkButton>
      </Col>
    </Row>
  );
};

export default TestimonialsCTA;
